<!-- 巡查上报-事件详情弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="60%"
      :z-index="12"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">事件信息</div>
      </div>
      <div class="center-top">
        <a-descriptions layout="vertical">
          <a-descriptions-item label="事件类别">
            2024年7月10日10:45:41
          </a-descriptions-item>
          <a-descriptions-item label="当前状态">张三</a-descriptions-item>
          <a-descriptions-item label="上报人"> 11111111111</a-descriptions-item>
          <a-descriptions-item label="上报时间"> 居民纠纷 </a-descriptions-item>
          <a-descriptions-item label="联系方式">居民上报 </a-descriptions-item>
          <a-descriptions-item label="上报来源"> 上报来源 </a-descriptions-item>
          <a-descriptions-item label="紧急程度"> 紧急程度 </a-descriptions-item>
          <a-descriptions-item label="详细地点" :span="2">
            河南省济源市徐舍镇烟山村欢堂39号1号楼2单元603号
          </a-descriptions-item>
          <a-descriptions-item label="报事内容" :span="3">
            {{ name }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 左边竖线样式写法 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">办理流程</div>
      </div>
      <div class="center-bottom">
        <a-timeline>
          <a-timeline-item color="#2A5CFF"
            >未受理
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div>
          </a-timeline-item>
          <a-timeline-item color="#2A5CFF"
            >已受理
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div>
          </a-timeline-item>
          <a-timeline-item color="#2A5CFF"
            >未办理
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div></a-timeline-item
          >
          <a-timeline-item color="#2A5CFF"
            >已办理
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div></a-timeline-item
          >
          <a-timeline-item color="#2A5CFF">
            未办结
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div>
          </a-timeline-item>
          <a-timeline-item color="#2A5CFF">
            已办结
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div>
          </a-timeline-item>
          <a-timeline-item color="#2A5CFF">
            已拒绝
            <div class="time-step-content">
              <img src="../../../../../assets/images/bg.png" alt="" />
              <div class="step-content">
                <div class="step-content-name">
                  张三
                  <span class="step-content-status">收到了</span>
                </div>
                <div class="step-content-time">8月7日 10:42</div>
              </div>
            </div>
            <div class="left-border">
              <div class="nbsp">&nbsp;</div>
              <div>办完了</div>
            </div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      // api,
      visible: false,
      title: "",
      name: "事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨阿斯顿法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法件萨法",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.top {
  background-color: #e8f3ff;
  border-radius: 10px;
  padding: 1%;
}
.center-top {
  margin-top: 1%;
  padding: 0 2%;
}
.center-bottom {
  margin-top: 1%;
  padding: 0 2%;
  /deep/.ant-timeline-item-content {
    font-size: 0.09rem;
    font-weight: bold;
    color: #2d3138;
  }
  // 时间轴部分样式
  .time-step-content {
    padding: 2% 2% 0.5% 2%;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
    .step-content {
      .step-content-name {
        display: flex;
        font-size: 0.09rem;
        color: #2a5cff;
        .step-content-status {
          color: #2d3138;
          margin: 0 5px;
        }
      }
      .step-content-time {
        font-size: 0.08rem;
        color: #7d8da6;
        font-weight: 100;
      }
    }
  }
  .left-border {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    padding: 0 2% 0 8%;
    font-size: 0.08rem;
    font-weight: 100;
    .nbsp {
      border-left: 1px solid #7d8da6;
      margin-right: 2px;
    }
  }
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-descriptions-item-label {
  color: #7d8da6;
}
/deep/.ant-descriptions-item-content {
  color: #f95a5a;
}
/deep/.ant-descriptions-item-content:nth-child(1) {
  color: #2d3138;
}
</style>